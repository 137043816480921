<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <Toast />
                <div class="p-grid">
                    <div class="p-col-12 p-md-2">
                        <span class="p-float-label">
                            <Dropdown :showClear="true" style="width: 100%" v-model="agentsModel" :options="agents" optionLabel="name" :filter="!this.isMobile()" />
                            <label>{{ $t('dropdown.placeholder.franchise') }}</label>
                        </span>
                    </div>
                    <div class="p-col-12 p-md-2">
                        <span class="p-float-label">
                            <Dropdown :showClear="true" style="width: 100%" v-model="yearModel" :options="yearsDdl" optionLabel="year" :filter="!this.isMobile()" />
                            <label>{{ $t('dropdown.placeholder.year') }}</label>
                        </span>
                    </div>
                    <div class="p-col-12 p-md-2">
                        <span class="p-float-label">
                            <Dropdown :showClear="true" style="width: 100%" v-model="monthModel" :options="visibleMonths" optionLabel="month" :filter="!this.isMobile()" />
                            <label>{{ $t('dropdown.placeholder.month') }}</label>
                        </span>
                    </div>
                    <div class="p-col-6 p-md-5 p-text-right"><Button type="button" :label="$t('buttons.show')" class="p-button-raised p-button-success" @click="showReport()" /></div>
                    <div class="p-col-6 p-md-1 p-text-right">
                        <Button type="button" :label="$t('buttons.savePdf')" :loading="showDownloadingData" icon="pi pi-download" class="p-button-raised p-button-info" @click="savePdf()" />
                    </div>
                </div>
                <DataTable v-show="showReportData" :value="reportData" v-model:expandedRows="expandedRows" ref="datatable" responsiveLayout="scroll">
                    <Column>
                        <template #body="slotProps">
                            <span class="indexColumn">{{ slotProps.index + 1 }}</span>
                        </template>
                    </Column>
                    <Column :expander="true" headerStyle="width: 3rem" />
                    <Column field="month" :header="$t('decont.shopCode')">
                        <template #body="{ data }">{{ data.solds.shopCode }} </template></Column
                    >
                    <Column field="solds" :header="$t('decont.totalIn')">
                        <template #body="{ data }">{{ formatCurrency(data.solds.in) }} </template></Column
                    >
                    <Column field="solds" :header="$t('decont.inTax')">
                        <template #body="{ data }">{{ formatCurrency(data.solds.inTax) }} </template></Column
                    >
                    <Column field="solds" :header="$t('decont.totalOut')">
                        <template #body="{ data }">{{ formatCurrency(data.solds.out) }} </template></Column
                    >
                    <Column field="solds" :header="$t('decont.outTax')">
                        <template #body="{ data }">{{ formatCurrency(data.solds.outTax) }} </template></Column
                    >
                    <Column field="solds" :header="$t('decont.profit')">
                        <template #body="{ data }">{{ formatCurrency(data.solds.profit) }} </template></Column
                    >
                    <Column field="solds" :header="$t('decont.profitNet')">
                        <template #body="{ data }">{{ formatCurrency(data.solds.profit) }} </template></Column
                    >
                    <Column field="solds" :header="$t('decont.invoiceAmount')">
                        <template #body="{ data }">{{ formatCurrency(data.solds.amount) }} </template></Column
                    >
                    <Column field="solds" :header="$t('decont.totalAmount')">
                        <template #body="{ data }">{{ formatCurrency(data.solds.amountWithTax) }} </template></Column
                    >
                    <template #expansion="{ data }">
                        <DecontReport :shopId="data.solds.shopId" :month="monthModel.id" :year="yearModel.id"></DecontReport>
                    </template>
                </DataTable>
            </div>
        </div>
    </div>
</template>

<script>
import { BoReportsApi } from '../../../../service/BoReportsApi';
import { BoApi } from '../../../../service/BoApi';
import { BoSaveReportsApi } from '../../../../service/BoSaveReportsApi';
import DecontReport from './DecontReport';

export default {
    name: 'decontShop',
    components: {
        DecontReport,
    },
    data() {
        return {
            BoApi: new BoApi(),
            BoReportsApi: new BoReportsApi(),
            BoSaveReportsApi: new BoSaveReportsApi(),
            yearModel: '',
            yearsDdl: [],
            agents: [],
            agentsModel: { name: 'All', id: 'All' },
            showReportData: false,
            showLoadingData: false,
            showDownloadingData: false,
            showGetDataBtn: false,
            errorKeyCount: 0,
            expandedRows: [],
            monthModel: '',
            monthsDdl: [],
            currentYear: 0,
            currentMonth: 0,
            reportData: [],
        };
    },
    beforeMount() {
        let crtDate = new Date();
        let crtYear = crtDate.getFullYear();
        this.currentYear = crtYear;
        var startYear = this.getReportsStartYear();
        for (let i = startYear; i <= crtYear; i++) {
            var yearObj = { year: i, id: i };
            this.yearsDdl.push(yearObj);
            if (i === crtYear) {
                this.yearModel = yearObj;
            }
        }
        let crtMonth = crtDate.getMonth() + 1;
        for (let i = 1; i <= 12; i++) {
            this.monthsDdl.push({ month: this.$t('months.' + i), id: i });
        }
        this.monthModel = { month: this.$t('months.' + crtMonth), id: crtMonth };
        this.currentMonth = crtMonth;
        this.getAgents();
    },
    watch: {
        agentsModel() {
            this.showReportData = false;
        },
        yearModel() {
            this.showReportData = false;
        },
        monthModel() {
            this.showReportData = false;
        },
    },
    computed: {
        visibleMonths() {
            if (this.yearModel.id == this.currentYear) {
                return this.monthsDdl.filter((m) => m.id <= this.currentMonth);
            } else {
                return this.monthsDdl;
            }
        },
    },
    methods: {
        getAgents() {
            this.BoApi.getBUnitsDdl()
                .then((agentsResponse) => {
                    let agentsDdlValues = [];
                    for (var i = 0; i < agentsResponse.data.length; i++) {
                        let shopItem = {
                            name: agentsResponse.data[i].name,
                            id: agentsResponse.data[i]._id,
                        };
                        agentsDdlValues.push(shopItem);
                    }
                    this.agents = agentsDdlValues;
                    this.agents.unshift(this.agentsModel);
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                    this.showToast('error', this.$t('alerts.generalError'));
                });
        },
        showReport() {
            if (this.notNullOrEmptyObject(this.agentsModel) && this.notNullOrEmptyObject(this.yearModel) && this.notNullOrEmptyObject(this.monthModel)) {
                this.BoReportsApi.getFranchiseDecont(this.agentsModel.id, this.yearModel.id, this.monthModel.id)
                    .then((response) => {
                        this.reportData = response.data;
                        this.showReportData = true;
                    })
                    .catch((error) => {
                        if (error.response && error.response.status === 401) {
                            this.$store.commit('login/endUserSession');
                            this.goto('login', { session: 'false' });
                        }
                    });
            } else {
                this.showToast('error', this.$t('alerts.invalidDdlFields'));
            }
        },
        savePdf() {
            if (this.notNullOrEmptyObject(this.agentsModel) && this.notNullOrEmptyObject(this.yearModel) && this.notNullOrEmptyObject(this.monthModel)) {
                this.showDownloadingData = true;
                this.BoSaveReportsApi.saveFranchiseDecont(this.agentsModel.id, this.yearModel.id, this.monthModel.id)
                    .then((response) => {
                        this.showDownloadingData = false;
                        let fileName = 'DecontFranchise_' + this.agentsModel.name + '_' + this.yearModel.id + '_' + this.monthModel.month + '.pdf';
                        let blob = new Blob([response.data], { type: 'application/pdf' });
                        let link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = fileName;
                        link.click();
                    })
                    .catch((error) => {
                        this.checkForUnauthorizedError(error);
                        this.showDownloadingData = false;
                        this.showToast('error', this.$t('alerts.generalError'));
                    });
            } else {
                this.showToast('error', this.$t('alerts.invalidDdlFields'));
            }
        },
        showToast(toastSeverity, text) {
            this.$toast.add({ severity: toastSeverity, summary: '', detail: text, life: this.getToastLifeSeconds() });
        },
    },
};
</script>
