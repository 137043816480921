<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <h5>{{ $t('menu.decont') }}</h5>
                <TabView v-model:activeIndex="activeTab">
                    <TabPanel :header="$t('decont.byShop')">
                        <DecontShop></DecontShop>
                    </TabPanel>
                    <TabPanel :header="$t('decont.byYear')">
                        <DecontYear></DecontYear>
                    </TabPanel>
                    <TabPanel :header="$t('decont.byAgent')">
                        <DecontAgent></DecontAgent>
                    </TabPanel>
                </TabView>
            </div>
        </div>
    </div>
</template>
<script>
import DecontShop from './Components/DecontShop';
import DecontYear from './Components/DecontYear';
import DecontAgent from './Components/DecontAgent';

export default {
    name: 'decont',
    components: {
        DecontShop,
        DecontYear,
        DecontAgent,
    },
};
</script>
