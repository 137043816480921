<template>
    <div>
        <div v-if="showReportData">
            <table class="whiteTable">
                <tr>
                    <td class="shop-details">
                        <div>
                            {{ reportData.Shop.ShopCode }}
                            {{ reportData.Shop.ShopName }}
                        </div>
                        <div>{{ reportData.Shop.ShopAddress }}</div>
                    </td>
                    <td class="report-date">
                        <div style="font-weight: bold; text-transform: uppercase">
                            {{ getCurrentDate() }}
                        </div>
                        <div>Situatia incasarilor/platilor</div>
                        <div>
                            in perioada
                            {{ formatCSDate(reportData.StartDate) }} -
                            {{ formatCSDate(reportData.EndDate) }}
                        </div>
                    </td>
                </tr>
            </table>
            <br />
            <table class="table">
                <tr>
                    <th rowspan="3">Data</th>
                    <th rowspan="3">Incasare Totala</th>
                    <th rowspan="3">Impozit Retinut</th>
                    <th colspan="9">Incasare - CDC 5%</th>
                    <th colspan="9">Castiguri efective</th>
                    <th rowspan="3">Profit</th>
                    <th rowspan="3">
                        Profit
                        <br />Net
                    </th>
                </tr>
                <tr>
                    <th>
                        Pariuri
                        <br />Sportive
                    </th>
                    <th>
                        Pariuri
                        <br />pe Numere
                    </th>
                    <th>
                        Power
                        <br />Six
                    </th>
                    <th>Power <br />Keno</th>
                    <th>Power <br />Roulette</th>
                    <th>Terminal</th>
                    <th>Live</th>
                    <th>Nsoft</th>
                    <th style="font-weight: bold">Total <br />Incasare - 5%</th>
                    <th>
                        Pariuri
                        <br />Sportive
                    </th>
                    <th>
                        Pariuri
                        <br />pe Numere
                    </th>
                    <th>
                        Power
                        <br />Six
                    </th>
                    <th>Power <br />Keno</th>
                    <th>Power <br />Roulette</th>
                    <th>Terminal</th>
                    <th>Live</th>
                    <th>Nsoft</th>
                    <th style="font-weight: bold">
                        Total
                        <br />Castiguri
                    </th>
                </tr>
                <tr style="font-size: 10px !important">
                    <th>1</th>
                    <th>2</th>
                    <th>3</th>
                    <th>4</th>
                    <th>5</th>
                    <th>6</th>
                    <th>7</th>
                    <th>8</th>
                    <th>9 = 1+...8</th>
                    <th>10</th>
                    <th>11</th>
                    <th>12</th>
                    <th>13</th>
                    <th>14</th>
                    <th>15</th>
                    <th>16</th>
                    <th>17</th>
                    <th>18 = 10+...+17</th>
                </tr>

                <tr v-for="(item, index) in reportData.Items" :key="'line' + index">
                    <td>
                        {{ index == reportData.Items.length - 1 ? 'TOTAL' : formatCSDate(item.Date) }}
                    </td>
                    <td>{{ formatCurrency(item.IncomesTotal + item.TotalInTax) }}</td>
                    <td>{{ formatCurrency(item.TotalOutTax) }}</td>
                    <td>{{ formatCurrency(item.IncomesPS) }}</td>
                    <td>{{ formatCurrency(item.IncomesLotto) }}</td>
                    <td>{{ formatCurrency(item.IncomesBSix) }}</td>
                    <td>{{ formatCurrency(item.IncomesKeno) }}</td>
                    <td>{{ formatCurrency(item.IncomesRoulette) }}</td>
                    <td>{{ formatCurrency(item.IncomesTerminal) }}</td>
                    <td>0</td>
                    <td>{{ formatCurrency(item.IncomesNsoft) }}</td>
                    <td>{{ formatCurrency(item.IncomesTotal) }}</td>
                    <td>{{ formatCurrency(item.OutcomesPS) }}</td>
                    <td>{{ formatCurrency(item.OutcomesLotto) }}</td>
                    <td>{{ formatCurrency(item.OutcomesBSix) }}</td>
                    <td>{{ formatCurrency(item.OutcomesKeno) }}</td>
                    <td>{{ formatCurrency(item.OutcomesRoulette) }}</td>
                    <td>{{ formatCurrency(item.OutcomesTerminal) }}</td>
                    <td>0</td>
                    <td>{{ formatCurrency(item.OutcomesNsoft) }}</td>
                    <td>{{ formatCurrency(item.OutcomesTotal) }}</td>
                    <td>{{ formatCurrency(item.TotalProfit + item.TotalOutTax) }}</td>
                    <td>{{ formatCurrency(item.TotalProfit) }}</td>
                </tr>
            </table>
            <br />
            <div class="comisioane left">
                <p style="font-weight: bold">COLABORATOR</p>

                <div>
                    <div class="leftCell">*) Comision perioada curenta</div>
                    <div class="rightCell" style="font-weight: bold">
                        {{ formatCurrency(reportData.ShopProfit) }}
                    </div>
                </div>
                <div>
                    <div class="leftCell" style="font-weight: normal">(-) Cheltuieli cu materiale promotionale</div>
                    <div class="rightCell">
                        {{ formatCurrency(0) }}
                    </div>
                </div>
                <div>
                    <div class="leftCell" style="font-weight: normal">(-) Alte cheltuieli ale societatii</div>
                    <div class="rightCell">
                        {{ formatCurrency(reportData.ShopExpenses) }}
                    </div>
                </div>
                <div>
                    <div class="leftCell" style="font-weight: normal">(-) Debite cumulate anterior</div>
                    <div class="rightCell">
                        <span>{{ reportData.Debts != 0 ? '-' : '' }}</span
                        ><span>{{ formatCurrency(reportData.Debts) }}</span>
                    </div>
                </div>
                <div id="report">
                    <div class="leftCell">Suma de facturat</div>
                    <div class="rightCell" style="font-weight: bold">
                        {{ formatCurrency(reportData.FinalAmount) }}
                    </div>
                </div>
                <br />
                <div style="clear: both">
                    <p>
                        {{ getCurrentDate() }}
                        {{ reportData.Username }}
                    </p>
                </div>
            </div>
            <div class="comisioane right">
                <div>
                    <div class="leftCell">
                        <p style="font-weight: bold">{{ reportData.Operator.Name }}</p>
                    </div>
                    <div class="rightCell"></div>
                </div>
                <div>
                    <div class="leftCell">Comision perioada curenta</div>
                    <div class="rightCell">{{ formatCurrency(reportData.ComisionOperator) }}</div>
                </div>
                <div>
                    <div class="leftCell">Impozi castiguri de virat AF</div>
                    <div class="rightCell">{{ formatCurrency(reportData.Items[itemsNo - 1].TotalOutTax) }}</div>
                </div>
                <div>
                    <div class="leftCell">CDA 5%</div>
                    <div class="rightCell">{{ formatCurrency(reportData.Items[itemsNo - 1].TotalInTax) }}</div>
                </div>
                <div>
                    <div class="leftCell"></div>
                    <div class="rightCell"></div>
                </div>
                <div id="report">
                    <div class="leftCell" style="font-weight: bold">Suma de recuperat de la partener</div>
                    <div class="rightCell" style="font-weight: bold">
                        {{ formatCurrency(reportData.OperatorAmount) }}
                    </div>
                </div>
            </div>
            <div style="clear: both"></div>
        </div>
        <br />
    </div>
</template>
<script>
import { BoReportsApi } from '../../../../service/BoReportsApi';
export default {
    name: 'decontReport',
    data() {
        return {
            reportData: [],
            showData: false,
            BoReportsApi: new BoReportsApi(),
            yearModel: '',
            monthModel: '',
            yearsDdl: [],
            monthsDdl: [],
            shops: [],
            shopsModel: { name: 'All', _id: 'all' },
            showReportData: false,
            showLoadingData: false,
            showDownloadingData: false,
            showGetDataBtn: false,
        };
    },
    props: {
        shopId: {
            type: String,
            default: '',
        },
        month: { type: Number },
        year: { type: Number },
        rowData: {
            type: Object,
        },
    },
    computed: {
        itemsNo() {
            return this.reportData.Items.length;
        },
    },
    mounted() {
        this.showReport();
    },
    methods: {
        showReport() {
            this.showLoadingData = true;
            this.BoReportsApi.getDecont(this.shopId, this.month, this.year)
                .then((response) => {
                    this.showLoadingData = false;
                    this.reportData = response.data;
                    this.showReportData = true;
                })
                .catch((error) => {
                    if (error.response && error.response.status === 401) {
                        this.$store.commit('login/endUserSession');
                        this.goto('login', { session: 'false' });
                    }
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.shop-details {
    margin-bottom: 10px;
    text-align: left;
}
.report-date {
    text-align: right;
    margin-bottom: 10px;
}
table {
    width: 100% !important;
    border-collapse: collapse;
}

.table th {
    border-collapse: collapse;
    border: 1px solid grey;
    text-align: center;
}
.table td {
    border-collapse: collapse;
    border: 1px solid grey;
    text-align: center;
}

.table tr:last-of-type {
    font-weight: bold;
}

.whiteTable {
    border: 1px solid white;
}
.comisioane {
    margin-left: 15px;
    text-align: left;
    width: 46%;
}
.right {
    float: right;
    margin-right: 15px;
    text-align: right;
}

.left {
    float: left;
    margin-left: 15px;
    text-align: left;
}
.comisioane .leftCell {
    width: 300px;
    text-align: left;
    display: inline-block;
}
.comisioane .rightCell {
    width: 120px;
    text-align: right;
    display: inline-block;
}
</style>
