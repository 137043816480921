<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <Toast />
                <div class="p-grid">
                    <div class="p-col-12 p-md-2">
                        <span class="p-float-label">
                            <Dropdown :showClear="true" style="width: 100%" v-model="shopsModel" :options="shops" optionLabel="name" :filter="!this.isMobile()" />
                            <label>{{ $t('dropdown.placeholder.shop') }}</label>
                        </span>
                    </div>
                    <div class="p-col-12 p-md-2">
                        <span class="p-float-label">
                            <Dropdown :showClear="true" style="width: 100%" v-model="yearModel" :options="yearsDdl" optionLabel="year" :filter="!this.isMobile()" />
                            <label>{{ $t('dropdown.placeholder.year') }}</label>
                        </span>
                    </div>
                    <div class="p-col-12 p-md-2">
                        <span class="p-float-label">
                            <Dropdown :showClear="true" style="width: 100%" v-model="monthModel" :options="monthsDdl" optionLabel="month" :filter="!this.isMobile()" />
                            <label>{{ $t('dropdown.placeholder.month') }}</label>
                        </span>
                    </div>
                    <div class="p-col-6 p-md-4 p-text-right"><Button type="button" :label="$t('buttons.show')" class="p-button-raised p-button-success" @click="showReport()" /></div>
                    <div class="p-col-6 p-md-1 p-text-right">
                        <Button type="button" :label="$t('buttons.savePdf')" :loading="showDownloadingData" icon="pi pi-download" class="p-button-raised p-button-info" @click="savePdf()" />
                    </div>
                    <div class="p-col-6 p-md-1 p-text-right">
                        <Button type="button" :label="$t('buttons.saveXls')" :loading="showDownloadingDataXls" icon="pi pi-download" class="p-button-raised p-button-info" @click="saveXls()" />
                    </div>
                </div>
                <DecontReport v-if="this.showReportData" :shopId="shopsModel.id" :month="monthModel.id" :year="yearModel.id"> </DecontReport>
            </div>
        </div>
    </div>
</template>

<script>
import { BoReportsApi } from '../../../../service/BoReportsApi';
import { BoApi } from '../../../../service/BoApi';
import { BoSaveReportsApi } from '../../../../service/BoSaveReportsApi';
import DecontReport from './DecontReport';

export default {
    name: 'decontShop',
    components: {
        DecontReport,
    },
    data() {
        return {
            BoApi: new BoApi(),
            BoReportsApi: new BoReportsApi(),
            BoSaveReportsApi: new BoSaveReportsApi(),
            yearModel: '',
            monthModel: '',
            yearsDdl: [],
            monthsDdl: [],
            shops: [],
            shopsModel: {},
            showReportData: false,
            showLoadingData: false,
            showDownloadingData: false,
            showGetDataBtn: false,
            errorKeyCount: 0,
            showDownloadingDataXls: false,
        };
    },
    beforeMount() {
        let crtDate = new Date();
        let crtYear = crtDate.getFullYear();
        var startYear = this.getReportsStartYear();
        for (let i = startYear; i <= crtYear; i++) {
            var yearObj = { year: i, id: i };
            this.yearsDdl.push(yearObj);
            if (i === crtYear) {
                this.yearModel = yearObj;
            }
        }
        let crtMonth = crtDate.getMonth() + 1;
        for (let i = 1; i <= 12; i++) {
            this.monthsDdl.push({ month: this.$t('months.' + i), id: i });
        }
        this.monthModel = { month: this.$t('months.' + crtMonth), id: crtMonth };
        this.getShops();
    },
    watch: {
        shopsModel() {
            this.showReportData = false;
        },
        yearModel() {
            this.showReportData = false;
        },
        monthModel() {
            this.showReportData = false;
        },
    },
    methods: {
        getShops() {
            this.BoApi.getShops('')
                .then((shopsResponse) => {
                    let shopsDdlValues = [];
                    for (var i = 0; i < shopsResponse.data.length; i++) {
                        let shopItem = {
                            name: shopsResponse.data[i].code + ' - ' + shopsResponse.data[i].name,
                            id: shopsResponse.data[i]._id,
                        };
                        shopsDdlValues.push(shopItem);
                    }
                    this.shops = shopsDdlValues;
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                    this.showToast('error', this.$t('alerts.generalError'));
                });
        },
        showReport() {
            this.showShopError = false;
            if (this.notNullOrEmptyObject(this.shopsModel) && this.notNullOrEmptyObject(this.monthModel) && this.notNullOrEmptyObject(this.yearModel)) {
                this.showReportData = true;
            } else {
                this.showToast('error', this.$t('alerts.invalidDdlFields'));
            }
        },
        savePdf() {
            if (this.notNullOrEmptyObject(this.shopsModel) && this.notNullOrEmptyObject(this.monthModel) && this.notNullOrEmptyObject(this.yearModel)) {
                this.showDownloadingData = true;
                this.BoSaveReportsApi.saveDecont(this.shopsModel.id, this.monthModel.id, this.yearModel.id)
                    .then((response) => {
                        this.showDownloadingData = false;
                        let fileName = 'Decont_' + this.shopsModel.name + '_' + this.yearModel.year + '_' + this.monthModel.month + '.pdf';
                        let blob = new Blob([response.data], { type: 'application/pdf' });
                        let link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = fileName;
                        link.click();
                    })
                    .catch((error) => {
                        this.checkForUnauthorizedError(error);
                        this.showDownloadingData = false;
                        this.showToast('error', this.$t('alerts.generalError'));
                    });
            } else {
                this.showToast('error', this.$t('alerts.invalidDdlFields'));
            }
        },
        saveXls() {
            if (this.notNullOrEmptyObject(this.shopsModel) && this.notNullOrEmptyObject(this.monthModel) && this.notNullOrEmptyObject(this.yearModel)) {
                this.showDownloadingDataXls = true;
                this.BoSaveReportsApi.saveDecontXls(this.shopsModel.id, this.monthModel.id, this.yearModel.id)
                    .then((response) => {
                        this.showDownloadingDataXls = false;
                        let blob = new Blob([response.data], { type: 'application/xls' });
                        let link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = 'Decont_' + this.shopsModel.name + '_' + this.yearModel.year + '_' + this.monthModel.month + '.xlsx';
                        link.click();
                    })
                    .catch((error) => {
                        this.checkForUnauthorizedError(error);
                        this.showDownloadingDataXls = false;
                        this.showToast('error', this.$t('alerts.generalError'));
                    });
            } else {
                this.showToast('error', this.$t('alerts.invalidDdlFields'));
            }
        },
        showToast(toastSeverity, text) {
            this.$toast.add({ severity: toastSeverity, summary: '', detail: text, life: this.getToastLifeSeconds() });
        },
    },
};
</script>
